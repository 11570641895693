import OpenAI from 'openai';

const API_KEY = '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';
const ASSISTANT_ID = 'asst_M0yFUJn2AUC52s1UBcgbzWJ8';

const SYSTEM_INSTRUCTIONS = `
You are Dr. Tooty (Farty's partner), a friendly and knowledgeable health AI assistant. Follow these guidelines:

1. Primary Focus: Digestive health, gut wellness, and gas-related topics are your specialty.
2. Secondary Topics: You can also provide general health and wellness advice, especially regarding:
   - Nutrition and healthy eating
   - Natural remedies and lifestyle tips
   - Basic health education
   - Preventive health measures

3. Response Guidelines:
   - Keep responses friendly, approachable, and easy to understand
   - Use a mix of scientific facts and practical advice
   - Include a brief medical disclaimer in every response
   - Keep responses under 150 words for readability
   - For any serious symptoms or concerns, always recommend consulting healthcare professionals
   - When discussing sensitive topics, maintain professionalism while being relatable

4. Off-limits Topics:
   - Do not provide specific medical diagnoses
   - Do not prescribe medications
   - Do not give specific medical treatment plans
   - For non-health related topics, politely redirect to health discussions

5. Tone and Style:
   - Be encouraging and positive
   - Use light humor when appropriate (especially for gas-related topics)
   - Balance professionalism with friendliness
   - Make complex topics accessible and understandable

Remember: You're a helpful guide promoting better health understanding, not a replacement for medical professionals.
`;

class AssistantService {
    constructor() {
        this.openai = new OpenAI({
            apiKey: API_KEY,
            dangerouslyAllowBrowser: true
        });
        this.thread = null;
    }

    async initializeThread() {
        try {
            const thread = await this.openai.beta.threads.create();
            this.thread = thread;
            return thread;
        } catch (error) {
            console.error('Failed to initialize thread:', error);
            throw new Error('Failed to start conversation');
        }
    }

    async getThread() {
        if (!this.thread) {
            await this.initializeThread();
        }
        return this.thread;
    }

    async sendMessage(content) {
        try {
            const thread = await this.getThread();

            // Add the message to the thread
            await this.openai.beta.threads.messages.create(thread.id, {
                role: 'user',
                content: content
            });

            // Run the assistant
            const run = await this.openai.beta.threads.runs.create(thread.id, {
                assistant_id: ASSISTANT_ID,
                instructions: SYSTEM_INSTRUCTIONS,
                metadata: {
                    timestamp: new Date().toISOString(),
                    type: 'health_consultation'
                }
            });

            // Wait for the run to complete
            let response = await this.waitForRunCompletion(thread.id, run.id);
            
            // Get the latest message
            const messages = await this.openai.beta.threads.messages.list(thread.id);
            const lastMessage = messages.data[0];

            return {
                content: lastMessage.content[0].text.value,
                status: response.status,
                metadata: response.metadata
            };

        } catch (error) {
            console.error('Error in sendMessage:', error);
            throw new Error('Failed to get response from assistant');
        }
    }

    async waitForRunCompletion(threadId, runId, maxRetries = 30) {
        let run;
        let retries = 0;

        do {
            if (retries >= maxRetries) {
                throw new Error('Response timeout');
            }

            run = await this.openai.beta.threads.runs.retrieve(threadId, runId);
            
            if (run.status === 'failed') {
                throw new Error('Assistant run failed');
            }
            
            if (run.status === 'requires_action') {
                throw new Error('Assistant requires action');
            }

            if (run.status !== 'completed') {
                await new Promise(resolve => setTimeout(resolve, 1000));
                retries++;
            }
        } while (run.status !== 'completed');

        return run;
    }

    async resetConversation() {
        try {
            if (this.thread) {
                await this.initializeThread();
            }
            return true;
        } catch (error) {
            console.error('Error resetting conversation:', error);
            throw new Error('Failed to reset conversation');
        }
    }
}

// Create and export a singleton instance
const assistantService = new AssistantService();
export default assistantService;