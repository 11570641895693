import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

// Import assets
import crown from '../assets/crwon.webp';
import fartemoji1 from "../assets/farts/fart5.webm";
import fartemoji2 from "../assets/farts/fart6.webm";
import fartemoji3 from "../assets/farts/fart7.webm";
import fartaudio1 from "../assets/farts/fart5.mp3";
import fartaudio2 from "../assets/farts/fart4.mp3";
import fartaudio3 from "../assets/farts/fart2.mp3";

// Matching breakpoint system with other sections
const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Animations
const fadeIn = keyframes`
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

// Styled Components
const HallOfFameContainer = styled.section`
  position: relative;
  padding: 3rem 1rem;
  overflow: hidden;
  animation: ${fadeIn} 0.6s ease-out;
  text-align: center;

  ${media.md} {
    padding: 4rem 1.5rem;
  }

  ${media.lg} {
    padding: 5rem 2rem;
  }
`;

const Title = styled.h2`
  font-family: 'Exo 2', sans-serif;
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1.2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
               0 0 20px rgba(76, 140, 47, 0.4),
               4px 4px 0 #8b4513;
  -webkit-text-stroke: 2px #4c8c2f;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: linear-gradient(to right, transparent, #8b4513, transparent);
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1rem, 1.2vw, 1.25rem);
  line-height: 1.6;
  color: #ffffff;
  margin: 0 auto 2rem;
  max-width: 800px;
  background: linear-gradient(to right, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PodiumContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 2rem;
  margin: 3rem auto;
  max-width: 1200px;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
`;

const PodiumCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 1.5rem;
  width: 100%;
  max-width: 320px;
  min-height: ${props => props.$height || "400px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: ${breakpoints.md}) {
    max-width: 280px;
    min-height: 350px;
  }
`;

const CrownIcon = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${float} 3s ease-in-out infinite;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  @media (max-width: ${breakpoints.md}) {
    width: 50px;
    height: 50px;
    top: -25px;
  }
`;

const RankNumber = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
  color: #ffffff;
  margin: 0.5rem 0;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);

  @media (max-width: ${breakpoints.md}) {
    font-size: 2rem;
  }
`;

const PrizeAmount = styled.div`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffcc00;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
  animation: ${pulse} 2s ease-in-out infinite;

  @media (max-width: ${breakpoints.md}) {
    font-size: 1.25rem;
  }
`;
// Continue with the remaining styled components

const WinnerName = styled.h3`
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0.5rem 0;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.4);

  @media (max-width: ${breakpoints.md}) {
    font-size: 1.25rem;
  }
`;

const FartMeter = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  color: #ffffff;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: #4c8c2f;
    font-weight: 700;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 1rem;
  }
`;

const FartButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
    background: rgba(255, 255, 255, 0.3);
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: ${breakpoints.md}) {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }
`;

const WalletAddress = styled.div`
  font-family: 'Roboto Mono', monospace;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s ease;
  word-break: break-all;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
  }

  @media (max-width: ${breakpoints.md}) {
    font-size: 0.7rem;
  }
`;

const PodiumVideo = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
  transition: opacity 0.3s ease;
  border-radius: 1rem;
  z-index: -1;

  ${PodiumCard}:hover & {
    opacity: 0.2;
  }
`;

const PrizeDescription = styled.div`
  margin-top: 3rem;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    font-family: 'Exo 2', sans-serif;
    font-size: 1.5rem;
    color: #ffcc00;
    margin-bottom: 1rem;
    text-shadow: 0 0 5px rgba(255, 204, 0, 0.4);
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    line-height: 1.6;
    color: #ffffff;
    background: linear-gradient(to right, #ffffff, #e0e0e0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 1.5rem;
    margin-top: 2rem;

    h3 {
      font-size: 1.25rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const HallOfFame = () => {
  const [playingStates, setPlayingStates] = useState([false, false, false]);
  const [mediaLoaded, setMediaLoaded] = useState([false, false, false]);
  const audioRefs = useRef([]);
  const videoRefs = useRef([]);

  const winners = [
    {
      rank: 1,
      name: "Gassy Gus",
      fartMeter: 100,
      prize: "$1,000",
      video: fartemoji1,
      audio: fartaudio1,
      wallet: "0x1234...5678"
    },
    {
      rank: 2,
      name: "Flatulent Frankie",
      fartMeter: 80,
      prize: "$500",
      video: fartemoji2,
      audio: fartaudio2,
      wallet: "0x8765...4321"
    },
    {
      rank: 3,
      name: "Stinky Stella",
      fartMeter: 60,
      prize: "$250",
      video: fartemoji3,
      audio: fartaudio3,
      wallet: "0x2468...1357"
    }
  ];

  // Handle media loading
  useEffect(() => {
    const handleMediaLoad = (index) => {
      setMediaLoaded(prev => {
        const newState = [...prev];
        newState[index] = true;
        return newState;
      });
    };

    // Set up event listeners for audio and video loading
    winners.forEach((_, index) => {
      const audio = audioRefs.current[index];
      const video = videoRefs.current[index];

      if (audio) {
        audio.addEventListener('loadeddata', () => handleMediaLoad(index));
      }
      if (video) {
        video.addEventListener('loadeddata', () => handleMediaLoad(index));
      }
    });

    // Cleanup
    return () => {
      winners.forEach((_, index) => {
        const audio = audioRefs.current[index];
        const video = videoRefs.current[index];

        if (audio) {
          audio.removeEventListener('loadeddata', () => handleMediaLoad(index));
        }
        if (video) {
          video.removeEventListener('loadeddata', () => handleMediaLoad(index));
        }
      });
    };
  }, []);

  const handlePlay = async (index) => {
    try {
      const audio = audioRefs.current[index];
      const video = videoRefs.current[index];

      if (!audio || !video) return;

      const newPlayingStates = [...playingStates];
      newPlayingStates[index] = !newPlayingStates[index];
      setPlayingStates(newPlayingStates);

      if (newPlayingStates[index]) {
        // Play media
        if (typeof audio.play === 'function') {
          const audioPromise = audio.play();
          if (audioPromise !== undefined) {
            audioPromise.catch(error => {
              console.warn("Audio playback failed:", error);
              newPlayingStates[index] = false;
              setPlayingStates([...newPlayingStates]);
            });
          }
        }

        if (typeof video.play === 'function') {
          const videoPromise = video.play();
          if (videoPromise !== undefined) {
            videoPromise.catch(error => {
              console.warn("Video playback failed:", error);
            });
          }
        }
      } else {
        // Pause media
        if (typeof audio.pause === 'function') {
          audio.pause();
          audio.currentTime = 0;
        }
        if (typeof video.pause === 'function') {
          video.pause();
          video.currentTime = 0;
        }
      }
    } catch (error) {
      console.warn("Media playback error:", error);
      const newPlayingStates = [...playingStates];
      newPlayingStates[index] = false;
      setPlayingStates(newPlayingStates);
    }
  };

  const handleAudioEnd = (index) => {
    try {
      const video = videoRefs.current[index];
      
      setPlayingStates(prev => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });

      if (video && typeof video.pause === 'function') {
        video.pause();
        video.currentTime = 0;
      }
    } catch (error) {
      console.warn("Error handling audio end:", error);
    }
  };

  // Cleanup effect
  useEffect(() => {
    return () => {
      try {
        audioRefs.current.forEach(audio => {
          if (audio && typeof audio.pause === 'function') {
            audio.pause();
            audio.currentTime = 0;
          }
        });
        videoRefs.current.forEach(video => {
          if (video && typeof video.pause === 'function') {
            video.pause();
            video.currentTime = 0;
          }
        });
      } catch (error) {
        console.warn("Cleanup error:", error);
      }
    };
  }, []);
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <HallOfFameContainer id="hall-of-fame">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <Title>FartyLand Hall of Fame</Title>
        <Description>
          The fart champions who have delivered the most unforgettable toots!
        </Description>

        <PodiumContainer>
          {winners.map((winner, index) => (
            <PodiumCard
              key={index}
              $height={`${400 - (index * 25)}px`}
              variants={itemVariants}
            >
              {index === 0 && <CrownIcon src={crown} alt="Crown" />}
              <PodiumVideo
                ref={el => videoRefs.current[index] = el}
                src={winner.video}
                loop
                muted
                playsInline
                preload="auto"
              />
              <audio
                ref={el => audioRefs.current[index] = el}
                src={winner.audio}
                onEnded={() => handleAudioEnd(index)}
                preload="auto"
              />
              <RankNumber>#{winner.rank}</RankNumber>
              <PrizeAmount>{winner.prize}</PrizeAmount>
              <WinnerName>{winner.name}</WinnerName>
              <FartMeter>
                Fart-O-Meter: <span>{winner.fartMeter}%</span>
              </FartMeter>
              <FartButton 
                onClick={() => handlePlay(index)}
                disabled={!mediaLoaded[index]}
              >
                {playingStates[index] ? '💨' : '🍑'}
              </FartButton>
              <WalletAddress title={winner.wallet}>
                {winner.wallet}
              </WalletAddress>
            </PodiumCard>
          ))}
        </PodiumContainer>

        <PrizeDescription>
          <h3>Turn Your Farts Into Cash! 💰</h3>
          <p>
            Every week, we reveal the Hall of Fame Farts, chosen by community votes. 
            The top 3 fart masters will win $1,000, $500, and $250 in $FARTY tokens 
            for 1st, 2nd, and 3rd place respectively.
          </p>
        </PrizeDescription>
      </motion.div>
    </HallOfFameContainer>
  );
};

export default HallOfFame;