import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Send, Loader2, RefreshCw } from 'lucide-react';
import logoImg from '../../assets/doc.png';
import assistantService from './assistantService';

const BackgroundLogo = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 1.5rem;
  overflow: hidden;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      135deg,
      rgba(76, 140, 47, 0.5) 0%,
      rgba(26, 47, 15, 0.5) 100%
    );
    backdrop-filter: blur(3px);
  }

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    opacity: 0.1;
  }
`;

const LaunchMessage = styled(motion.div)`
  position: absolute;
  top: -30px;
  left: 20%;
  transform: translateX(-50%);
  background: linear-gradient(135deg, #4c8c2f, #3a6b24);
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.4);
  z-index: 2;

  h3 {
    color: var(--accent-gold, #FFC107);
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const AssistantCard = styled.div`
  width: 100%;
  max-width: 600px;
  background: transparent;
  border-radius: 1.5rem;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(76, 140, 47, 0.3);
  height: 500px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 450px;
    padding: 1rem;
  }
`;

const AssistantHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
  border-radius: 1rem;
  backdrop-filter: blur(5px);
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const AILogo = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid rgba(76, 140, 47, 0.5);
  padding: 2px;
  background: rgba(255, 255, 255, 0.1);
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  h2 {
    color: #ffffff;
    margin: 0;
    font-size: 1.25rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
  }
`;

const HeaderControls = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const IconButton = styled(motion.button)`
  background: rgba(76, 140, 47, 0.3);
  border: none;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(76, 140, 47, 0.5);
  }
`;

const ErrorMessage = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(220, 53, 69, 0.9);
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  max-width: 80%;
  z-index: 10;
`;
const ChatContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-bottom: 0.75rem;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(76, 140, 47, 0.2);
  position: relative;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(76, 140, 47, 0.5);
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const MessageWrapper = styled.div`
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Message = styled.div`
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  max-width: 85%;
  ${props => props.$isUser ? `
    background: #4c8c2f;
    margin-left: auto;
    border-bottom-right-radius: 0.25rem;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
  ` : `
    background: #3a6b24;
    margin-right: auto;
    border-bottom-left-radius: 0.25rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  `}
  color: #ffffff;
  font-size: 0.9rem;
  line-height: 1.5;
  position: relative;
  white-space: pre-wrap;
  
  @media (max-width: 768px) {
    font-size: 0.85rem;
    padding: 0.6rem 0.8rem;
  }
`;

const TypingIndicator = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  opacity: 0.7;
  font-size: 0.85rem;
  padding: 0.5rem 0.75rem;
  background: rgba(76, 140, 47, 0.2);
  border-radius: 1rem;
  margin: 0.5rem 0;
  width: fit-content;
`;

const LoadingSpinner = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  z-index: 1;
  background: rgba(0, 0, 0, 0.15);
  padding: 0.75rem;
  border-radius: 1rem;
  backdrop-filter: blur(5px);
`;

const SuggestedQuestions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 0.4rem;
  margin-bottom: 0.5rem;
  max-height: 80px;
  overflow-y: auto;
  padding-right: 0.25rem;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(76, 140, 47, 0.5);
    border-radius: 1.5px;
  }
`;

const SuggestedQuestion = styled(motion.button)`
  background: rgba(76, 140, 47, 0.3);
  border: 1px solid rgba(76, 140, 47, 0.3);
  border-radius: 0.75rem;
  padding: 0.4rem 0.8rem;
  color: #ffffff;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.2s;
  text-align: left;
  line-height: 1.2;

  &:hover {
    background: rgba(76, 140, 47, 0.5);
    transform: translateY(-1px);
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(76, 140, 47, 0.3);
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  color: #ffffff;
  font-size: 0.9rem;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    border-color: #4c8c2f;
    box-shadow: 0 0 0 2px rgba(76, 140, 47, 0.2);
  }
`;

const SendButton = styled(motion.button)`
  background: #4c8c2f;
  border: none;
  border-radius: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;

  &:hover {
    background: #3a6b24;
  }

  &:disabled {
    background: #2c5115;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
const HealthAssistantInterface = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "I'm Tooty, your AI guide to answer any questions from gas and digestion to healthy living, I’ve got you covered with recommendations to keep your gut & life, balanced and happy :)",
      isUser: false,
      timestamp: new Date(),
    }
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const chatContainerRef = useRef(null);
  
  const suggestedQuestions = [
    "Is holding farts bad?",
    "Why is farting taboo?",
    "Why do we fart?",
    "Foods causing gas?",
    "Reduce bloating tips",
    "Gut-friendly foods",
  ];

  useEffect(() => {
    // Initialize assistant thread
    const initAssistant = async () => {
      try {
        await assistantService.initializeThread();
      } catch (error) {
        setError("Connection issue. Please refresh.");
        setTimeout(() => setError(null), 3000);
      }
    };
    
    initAssistant();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (!input.trim() || isTyping) return;

    const userMessage = {
      id: messages.length + 1,
      text: input.trim(),
      isUser: true,
      timestamp: new Date(),
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);
    setError(null);

    try {
      const response = await assistantService.sendMessage(input.trim());
      
      setMessages(prev => [...prev, {
        id: prev.length + 1,
        text: response.content,
        isUser: false,
        timestamp: new Date(),
      }]);
    } catch (error) {
      console.error('Error getting response:', error);
      setError("Unable to respond. Please try again.");
      setTimeout(() => setError(null), 3000);
    } finally {
      setIsTyping(false);
    }
  };

  const handleReset = async () => {
    try {
      await assistantService.resetConversation();
      setMessages([{
        id: 1,
        text: "I'm Tooty, your AI guide to answer any questions from gas and digestion to healthy living, I’ve got you covered with recommendations to keep your gut & life, balanced and happy :)",
        isUser: false,
        timestamp: new Date(),
      }]);
    } catch (error) {
      setError("Couldn't reset chat. Please refresh.");
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <motion.div style={{ position: 'relative' }}>
      <LaunchMessage
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <h3>Tooty (Farty's Partner): Your Health AI Buddy 🌟</h3>
      </LaunchMessage>

      <AssistantCard
        as={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <BackgroundLogo>
          <img src={logoImg} alt="Background Logo" />
        </BackgroundLogo>

        {error && (
          <ErrorMessage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {error}
          </ErrorMessage>
        )}

        <AssistantHeader>
          <HeaderLeft>
            <AILogo src={logoImg} alt="Tooty" />
            <HeaderTitle>
              <h2> Tooty</h2>
              <span>Your Health & Wellness Guide</span>
            </HeaderTitle>
          </HeaderLeft>
          <HeaderControls>
            <IconButton
              as={motion.button}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleReset}
              title="Reset conversation"
            >
              <RefreshCw size={16} />
            </IconButton>
          </HeaderControls>
        </AssistantHeader>

        <ChatContainer ref={chatContainerRef}>
          {messages.map((message) => (
            <MessageWrapper key={message.id}>
              <Message $isUser={message.isUser}>
                {message.text}
              </Message>
            </MessageWrapper>
          ))}
          {isTyping && (
            <TypingIndicator
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <LoadingSpinner>
                <Loader2 size={16} />
              </LoadingSpinner>
              Tooty is typing...
            </TypingIndicator>
          )}
        </ChatContainer>

        <InputContainer>
          <SuggestedQuestions>
            {suggestedQuestions.map((question, index) => (
              <SuggestedQuestion
                key={index}
                as={motion.button}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  setInput(question);
                  handleSend();
                }}
              >
                {question}
              </SuggestedQuestion>
            ))}
          </SuggestedQuestions>

          <InputWrapper>
            <Input
              type="text"
              placeholder="Ask me about health & wellness..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={isTyping}
            />
            <SendButton
              as={motion.button}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSend}
              disabled={!input.trim() || isTyping}
            >
              {isTyping ? (
                <LoadingSpinner>
                  <Loader2 size={18} />
                </LoadingSpinner>
              ) : (
                <Send size={18} />
              )}
            </SendButton>
          </InputWrapper>
        </InputContainer>
      </AssistantCard>
    </motion.div>
  );
};

export default HealthAssistantInterface;