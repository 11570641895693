// src/pages/HomePage.js
import React from 'react';
import Banner from '../components/Hero/HeroSection';
import FartGridComponent from '../components/FartGrid';
import TokenEconomicsSections from '../components/TokenEconomics';
import PartnersSection from '../components/MovingStrip';
import Roadmap from '../components/Rodmap';
import HowToBuy from '../components/HowToBuy';
import HallOfFame from '../components/HallOfFame';
import About from '../components/About';
import CTASection from '../components/CTASection';
import GameSection from '../components/GameSection';

const HomePage = () => {
  return (
    <>
      <Banner />
      <GameSection/>
      <About />
     
      <HallOfFame />
     
      <FartGridComponent />
      <PartnersSection />
      <Roadmap />
      <TokenEconomicsSections />
      <CTASection />
      <HowToBuy />
    </>
  );
};

export default HomePage;
