import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ArrowClockwise, House, TelegramLogo } from "@phosphor-icons/react";
import loveLogo from "../assets/lovelogo.webp";

const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-10px) rotate(5deg); }
`;

// Updated Container dimensions
const Container = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e4c14;
  z-index: 10;
  animation: ${fadeIn} 0.6s ease-out;
  padding: 10px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
      repeating-linear-gradient(
        0deg,
        transparent,
        transparent 19px,
        rgba(255, 255, 255, 0.03) 19px,
        rgba(255, 255, 255, 0.03) 20px
      ),
      repeating-linear-gradient(
        90deg,
        transparent,
        transparent 19px,
        rgba(255, 255, 255, 0.03) 19px,
        rgba(255, 255, 255, 0.03) 20px
      );
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: url(${loveLogo}) no-repeat center center;
    background-size: 200px;
    opacity: 0.1;
    filter: blur(1px);
    animation: ${float} 6s ease-in-out infinite;
  }
`;

// Updated Content dimensions
const Content = styled.div`
  background: rgba(14, 36, 10, 0.95);
  border: 1px solid rgba(76, 140, 47, 0.3);
  border-radius: 12px;
  padding: 15px;
  width: 95%;
  max-width: 340px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  z-index: 1;

  ${media.md} {
    padding: 20px;
    max-width: 360px;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 12px;
    width: 98%;
    max-width: 320px;
  }
`;

// Updated Score container dimensions
const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 8px;

  ${media.md} {
    gap: 8px;
    margin-bottom: 15px;
    padding: 12px;
  }
`;
const Title = styled.h2`
  font-size: clamp(1.4rem, 3vw, 1.6rem);
  font-weight: bold;
  color: #fff;
  margin: 0 0 12px 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.2;

  ${media.md} {
    margin-bottom: 15px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 10px;
  }
`;

const ScoreLabel = styled.p`
  font-size: clamp(0.9rem, 1.2vw, 1rem);
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const ScoreValue = styled.span`
  font-size: clamp(1.8rem, 2.5vw, 2rem);
  font-weight: bold;
  color: #ffcc00;
  text-shadow: 0 0 10px rgba(255, 204, 0, 0.4);
  animation: ${float} 2s ease-in-out infinite;
`;

const MessageText = styled.p`
  font-size: clamp(0.85rem, 1.1vw, 0.95rem);
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 12px 0;
  line-height: 1.4;
  padding: 0 5px;
`;



const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: none;
  margin: 8px auto 0;

  ${media.md} {
    gap: 6px;
    margin-top: 10px;
  }
`;

const Button = styled.button`
  position: relative;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  overflow: hidden;
  transition: all 0.25s ease;
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => {
      if (props.variant === 'telegram') return 'linear-gradient(135deg, #ffd700, #8b4513)';
      if (props.variant === 'menu') return 'linear-gradient(135deg, #1e4c14, #0d2309)';
      return 'linear-gradient(135deg, #4c8c2f, #2a5517)';
    }};
    border-radius: 6px;
    opacity: 0.9;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-1px);
    &::before {
      opacity: 1;
    }
  }

  span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: ${breakpoints.sm}) {
    height: 34px;
  }
`;

const HighlightText = styled.span`
  color: #ffcc00;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  font-weight: 600;
`;

const GameOver = ({ score, onPlayAgain, onMainMenu }) => {
  const handlePlayForMoney = () => {
    window.open('https://t.me/FartyLandbot', '_blank');
  };

  const getEncouragingMessage = (score) => {
    if (score >= 1000) {
      return "Legendary Fart Master! 🏆 Hall of Fame worthy!";
    } else if (score >= 500) {
      return "Amazing skills! 🌟 You're a natural!";
    } else if (score >= 100) {
      return "Great run! 🚀 Keep aiming higher!";
    } else {
      return "Nice start! 💪 Ready to beat that score?";
    }
  };

  const getScoreRank = (score) => {
    if (score >= 1000) return "Master Farter";
    if (score >= 500) return "Pro Farter";
    if (score >= 100) return "Skilled Farter";
    return "Rookie Farter";
  };

  return (
    <Container>
      <Content>
        <Title>Oops! The Fart Escaped!</Title>
        
        <ScoreContainer>
          <ScoreLabel>You achieved</ScoreLabel>
          <ScoreValue>{score}</ScoreValue>
          <ScoreLabel>Farty Points</ScoreLabel>
          <HighlightText>{getScoreRank(score)}</HighlightText>
        </ScoreContainer>

        <MessageText>
          {getEncouragingMessage(score)}
        </MessageText>

       
        <ButtonContainer>
          <Button onClick={onPlayAgain}>
            <span>
              <ArrowClockwise weight="bold" />
              Play Again
            </span>
          </Button>

          <Button variant="menu" onClick={onMainMenu}>
            <span>
              <House weight="bold" />
              Main Menu
            </span>
          </Button>

          <Button variant="telegram" onClick={handlePlayForMoney}>
            <span>
              <TelegramLogo weight="bold" />
              Play For Money
            </span>
          </Button>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default GameOver;