import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Rocket, TelegramLogo, Question, ArrowLeft } from "@phosphor-icons/react";
import loveLogo from "../assets/lovelogo.webp";

// Breakpoints system
const breakpoints = {
  xs: '360px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
};

const media = {
  xs: `@media (min-width: ${breakpoints.xs})`,
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`
};

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-10px) rotate(5deg); }
`;

// Base container with background logo
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1e4c14;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 0.6s ease-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      repeating-linear-gradient(
        0deg,
        transparent,
        transparent 19px,
        rgba(255, 255, 255, 0.03) 19px,
        rgba(255, 255, 255, 0.03) 20px
      ),
      repeating-linear-gradient(
        90deg,
        transparent,
        transparent 19px,
        rgba(255, 255, 255, 0.03) 19px,
        rgba(255, 255, 255, 0.03) 20px
      );
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: url(${loveLogo}) no-repeat center center;
    background-size: 150px;
    opacity: 0.1;
    filter: blur(1px);
    animation: ${float} 6s ease-in-out infinite;
    pointer-events: none;
  }
`;

// Main content container
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 90%;
  max-width: 320px;
  z-index: 1;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

  ${media.md} {
    gap: 15px;
    max-width: 340px;
    padding: 20px;
  }

  @media (max-width: ${breakpoints.sm}) {
    gap: 10px;
    padding: 12px;
    max-width: 280px;
  }
`;

// Logo with reduced size
const GameLogo = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${media.md} {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 45px;
    height: 45px;
  }
`;

// Adjusted title sizing
const Title = styled.h1`
  font-size: clamp(1.5rem, 3vw, 1.8rem);
  font-weight: bold;
  color: #fff;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  text-align: center;
  letter-spacing: 0.05em;
`;
// Compact description
const Description = styled.p`
  font-size: clamp(0.85rem, 1.1vw, 0.95rem);
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  text-align: center;
  line-height: 1.4;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  padding: 0 5px;
`;

// More compact button container
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 220px;
  margin-top: 5px;

  ${media.md} {
    gap: 8px;
    max-width: 240px;
  }

  @media (max-width: ${breakpoints.sm}) {
    gap: 5px;
    max-width: 200px;
  }
`;

// Smaller buttons
const Button = styled.button`
  position: relative;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 6px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => {
      if (props.variant === 'money') return 'linear-gradient(135deg, #ffd700, #8b4513)';
      if (props.variant === 'tutorial') return 'linear-gradient(135deg, #1e4c14, #0d2309)';
      return 'linear-gradient(135deg, #4c8c2f, #2a5517)';
    }};
    border-radius: 6px;
    opacity: 0.9;
    z-index: 1;
  }

  &:hover {
    transform: translateY(-1px);
    &::before {
      opacity: 1;
    }
  }

  span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  ${media.md} {
    height: 38px;
    font-size: 0.85rem;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

// Tutorial modal overlay
const TutorialOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 300px;
  background: rgba(14, 36, 10, 0.95);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 100;
  animation: ${fadeIn} 0.3s ease-out;

  ${media.md} {
    max-width: 320px;
  }
`;

const TutorialTitle = styled.h2`
  font-size: 1.2rem;
  color: #fff;
  margin: 0 0 12px 0;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.05em;
`;

const TutorialSection = styled.div`
  margin-bottom: 12px;

  h3 {
    color: #ffcc00;
    font-size: 0.9rem;
    margin: 0 0 6px 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  p, ul {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.85rem;
    line-height: 1.4;
    margin: 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    
    li {
      margin: 6px 0;
      padding-left: 15px;
      position: relative;

      &::before {
        content: '•';
        position: absolute;
        left: 0;
        color: #4c8c2f;
      }
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const HighlightText = styled.span`
  color: #ffcc00;
  text-shadow: 0 0 5px rgba(255, 204, 0, 0.8);
  font-weight: 600;
`;

// Component implementation
const GameIntroScreen = ({ onPlayForFun }) => {
  const [showTutorial, setShowTutorial] = useState(false);

  const handlePlayForMoney = () => {
    window.open('https://t.me/FartyLandbot', '_blank');
  };

  const toggleTutorial = () => {
    setShowTutorial(!showTutorial);
  };

  return (
    <Container>
      <Content>
        <GameLogo>
          <img src={loveLogo} alt="Fartiland" />
        </GameLogo>

        <Title>Ready to Fart?</Title>
        <Description>
          Choose the game mode and let the{' '}
          <HighlightText>farting</HighlightText>{' '}begin! 💨
        </Description>

        <ButtonsContainer>
          <Button onClick={onPlayForFun}>
            <span>
              <Rocket weight="fill" />
              Play For Fun
            </span>
          </Button>

          <Button variant="tutorial" onClick={toggleTutorial}>
            <span>
              <Question weight="fill" />
              How To Play
            </span>
          </Button>

          <Button variant="money" onClick={handlePlayForMoney}>
            <span>
              <TelegramLogo weight="fill" />
              Play For Money
            </span>
          </Button>
        </ButtonsContainer>
      </Content>

      {showTutorial && (
        <TutorialOverlay>
          <CloseButton onClick={toggleTutorial}>
            <ArrowLeft weight="bold" />
          </CloseButton>
          
          <TutorialTitle>HOW TO PLAY</TutorialTitle>

          <TutorialSection>
            <h3>Controls:</h3>
            <p><HighlightText>Space Bar:</HighlightText> PRESS TO RELEASE FART.</p>
          </TutorialSection>

          <TutorialSection>
            <h3>Gameplay Tips:</h3>
            <ul>
              <li>
                <strong>Watch the Fart Bar:</strong> Release gas to keep it from getting too full or empty.
              </li>
              <li>
                <strong>Time Your Farts:</strong> Only release with passing cars.
              </li>
              <li>
                <strong>Manage Pressure:</strong> Don't let it get too full or empty!
              </li>
            </ul>
          </TutorialSection>

          <TutorialSection>
            <h3>Goal:</h3>
            <p>Keep going as long as you can by timing your releases perfectly!</p>
          </TutorialSection>
        </TutorialOverlay>
      )}
    </Container>
  );
};

export default GameIntroScreen;